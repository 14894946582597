//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import { i18n } from '@/i18n';
export default {
  name: 'app-empty-permissions-page',

  methods: {

    ...mapActions({
      doSignout: 'auth/doSignout',
    }),

    // i18n for localization 

     i18n(key, args) {
      // return i18n(code);
      return this.$t(key, args);
    },
  },

   computed: {
  ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
     // this computed for enableRtl
     
     isRTL() {
      // return localStorage.getItem('language') == 'ar'
      return this.currentLanguageCode == 'ar'
    },
  },
};
